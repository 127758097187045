import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
  );
  console.log("route: ", window.location)
  //cant use document.cookie for this because the cookie is always empty
  //reroutes are being handled in express side
root.render(
    <App />
  );
